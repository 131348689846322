import { Route, Routes } from "react-router-dom";
import { authRoutes } from "./routes/allRoutes";
import "./App.css";

function App() {
  return (
    <Routes>
      {authRoutes.map((route: any, idx: number) => (
        <Route
          key={idx + "_" + route.path}
          path={route.path}
          element={<route.component />}
        />
      ))}
    </Routes>
  );
}

export default App;
