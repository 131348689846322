import { lazy } from "react";
import { Navigate } from "react-router-dom";

export interface RouteProps {
  path: string;
  component: any;
}

const authRoutes: Array<RouteProps> = [
  {
    path: "/",
    component: () => <Navigate replace to="/features" />,
  },
  {
    path: "/features",
    component: lazy(() => import("../pages/Service")),
  },
];

export { authRoutes };
